<template>
  <v-app>
    <!-- <v-app-bar app color="grey lighten-3" flat height="35"> </v-app-bar> -->

    <v-main
      style="
        background: url('https://cdn.vuetifyjs.com/images/parallax/material.jpg')
          no-repeat center center fixed !important;
        background-size: cover;
      "
    >
      <v-container fluid>
        <v-row align="center">
          <v-spacer></v-spacer>
          <v-col cols="3">
            <!--  -->
            <app-card :loading="loading">
              <template slot="progress">
                <v-progress-linear
                  color="deep-purple"
                  height="10"
                  indeterminate
                ></v-progress-linear>
              </template>
              <v-card-text>
                <v-img
                  height="150"
                  style="background-color: rgba(255, 255, 255, 0) !important"
                  aspect-ratio="3"
                  contain
                  :src="require('@/assets/cover.png')"
                  class="grey darken-4 rounded-lg"
                ></v-img>
                <!-- <div class="text-h5 text--primary">Inicia tu sesión</div> -->
                <v-row class="mt-2">
                  <v-spacer></v-spacer>
                  <v-col cols="11"
                    ><v-text-field
                      color="logistikgps"
                      onfocus="this.removeAttribute('readonly');"
                      label="Usuario"
                      v-model="usuarioAlias"
                      ref="usuarioAlias"
                      readonly
                      prepend-icon="mdi-account"
                    ></v-text-field>

                    <v-text-field
                      color="logistikgps"
                      onfocus="this.removeAttribute('readonly');"
                      label="Contraseña"
                      type="password"
                      v-model="password"
                      readonly
                      prepend-icon="mdi-key"
                      v-on:keyup.enter="login()"
                      ref="password"
                    ></v-text-field
                  ></v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </v-card-text>
              <v-card-text class="text-center">
                <v-btn
                  @click="login()"
                  color="logistikgps"
                  class="cyan"
                  min-width="150"
                  dark
                >
                  Iniciar sesión
                </v-btn>
              </v-card-text>
            </app-card>

            <!--  -->
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  data: () => ({
    usuarioAlias: '',
    password: '',
    /** */
    links: ['Dashboard', 'Messages', 'Profile', 'Updates'],
  }),
  computed: {
    ...mapState('auth', { loading: 'isAuthenticatePending' }),
  },
  methods: {
    ...mapMutations('auth', {
      unsetAuthenticatePending: 'unsetAuthenticatePending',
    }),
    notificacion(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    //
    login() {
      //
      if (this.$refs.usuarioAlias.value == '') {
        this.$refs.usuarioAlias.style.border = 'thin solid #FF4C25';
      } else if (this.$refs.password.value == '') {
        this.$refs.password.style.border = 'thin solid #FF4C25';
      } else {
        //autenticarse
        this.$store
          .dispatch('auth/authenticate', {
            strategy: 'local',
            usuarioAlias: this.usuarioAlias,
            usuarioPassword: this.password,
            // usuarioAlias: "070501123",
            // password: "070501123"
          })
          .then((result) => {
            this.unsetAuthenticatePending();
            this.$router.push('/');
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
  },
  mounted() {
    this.$refs.usuarioAlias.focus();
  },
};
</script>
