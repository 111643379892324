<template>
  <v-card>
    <!-- /** */ -->
    <v-toolbar color="primary" dark flat>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="headline">
            Detalle {{ $route.params.departamento }} ({{ formatDate }})
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-toolbar>
    <!-- /** */ -->
    <v-tabs v-model="tab" background-color="primary" dark>
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.tab">
        <v-card flat>
          <!-- /** */ -->
          <v-card-text v-if="item.tab == 'Por municipio'">
            <v-row>
              <v-col cols="12">
                <dptoGraficas
                  :prop_departamento="$route.params.departamento"
                  :prop_fecha="$route.params.fecha"
                ></dptoGraficas>
              </v-col>
            </v-row>
          </v-card-text>
          <!-- /** */ -->
          <v-card-text v-if="item.tab == 'Mapa'"
            ><findMapa
              :prop_departamento="$route.params.departamento"
              :prop_fecha="$route.params.fecha"
            ></findMapa></v-card-text
          ><!-- /** */ -->
          <v-card-text v-if="item.tab == 'Pilotos'"
            ><pilotos
              :prop_departamento="$route.params.departamento"
              :prop_fecha="$route.params.fecha"
            ></pilotos></v-card-text
          ><!-- /** */ -->
          <v-card-text v-if="item.tab == 'Detalle'"
            ><findPedidos
              :prop_departamento="$route.params.departamento"
              :prop_fecha="$route.params.fecha"
            ></findPedidos></v-card-text
          ><!-- /** */ -->
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import dptoGraficas from '../../components/chartjs/dptoGraficas.vue';
import findMapa from './findMapa.vue';
import findPedidos from './../DashboardContent/DashboardTablaGuias';
import pilotos from './pilotos.vue';
//
import { mapState, mapGetters, mapActions } from 'vuex';
import LoginVue from '../Login.vue';
export default {
  components: {
    dptoGraficas,
    findMapa,
    pilotos,
    findPedidos,
  },
  data() {
    return {
      tab: null,
      items: [
        { tab: 'Por municipio' },
        { tab: 'Mapa' },
        { tab: 'Pilotos' },
        { tab: 'Detalle' },
      ],
    };
  },
  methods: {
    ...mapActions('Gpsguias', {
      getguias: 'get',
      findguias: 'find',
      patchGuia: 'patch',
      crearGuia: 'create',
    }),
  },
  mounted() {
    this.findguias({
      query: {
        'guiaReceptor.guiaReceptorDepartamento': this.$route.params.departamento
          .departamento,
      },
    }).then((result_guiaspordepartamento) => {
      console.log('guias por departamento');
      console.log(result_guiaspordepartamento);
    });
  },
  computed: {
    formatDate() {
      return this.$route.params.fecha
        ? moment(this.$route.params.fecha).format('MMMM-YYYY')
        : '';
    },
  },
};
</script>
